<template>
  <div>
    <div class="mb-3">
      <b-tabs align="center">
        <b-tab
          v-for="(item, index) in status"
          :key="index"
          :title="item.label"
          @click="fetchOrders(item.value)"
        />
      </b-tabs>
    </div>
    <MyTable
      :rows="rows"
      :columns="columns"
      :date-range-enabled="true"
      :date-range-field="'pickup_date'"
      :is-loading="isLoading"
    >
      <template v-slot="{props}">
        <span v-if="props.column.field === 'group'">
          <b-button
            v-if="props.row.delivery"
            :to="{
              name: 'delivery-summary',
              params: { deliveryId: props.row.delivery.id },
            }"
            variant="danger"
          >
            {{ props.row.delivery.id }}
          </b-button>
          <b-button
            v-else
            variant="danger"
          >
            {{ props.row.delivery.id }}
          </b-button>
        </span>

        <span v-else-if="props.column.field === 'po_expiry_date'">
          {{ $helpers.formatDate(props.row.po_expiry_date) }}
        </span>

        <span v-else-if="props.column.field === 'date_added'">
          {{ $helpers.formatDate(props.row.date_added) }}
        </span>

        <span v-else-if="props.column.field === 'delivery_date'">
          {{ $helpers.formatDateTime(props.row.delivery_date) }}
        </span>

        <span v-else-if="props.column.field === 'sender_address'">
          {{ props.row.sender.complete }}
        </span>

        <span v-else-if="props.column.field === 'recipient_address'">
          {{ props.row.recipient.complete }}
        </span>

        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </MyTable>
    <b-col cols="12 text-center">
      <b-button
        variant="danger"
        :to="{ name: 'dashboard' }"
        class="mr-1"
      >Back to Dashboard</b-button>
    </b-col>
  </div>
</template>

<script>
import axios from '@axios'
import store from '@/store/index'
import { MyTable } from '@/components'

export default {
  name: 'MyAssignedTracker',
  components: {
    MyTable,
  },
  data() {
    const columns = [
      {
        label: 'Delivery ID',
        field: 'group',
        thClass: 'text-center align-middle',
        tdClass: 'text-center align-middle',
      },
      {
        label: 'PO Number',
        field: 'po_number',
        thClass: 'text-center align-middle',
        tdClass: 'text-center align-middle',
      },
      {
        label: 'PO Expiry Date',
        field: 'po_expiry_date',
        thClass: 'text-center align-middle',
        tdClass: 'text-center align-middle',
      },
      {
        label: 'Order Date',
        field: 'date_added',
        thClass: 'text-center align-middle',
        tdClass: 'text-center align-middle',
      },
      {
        label: 'Delivery Date',
        field: 'delivery_date',
        thClass: 'text-center align-middle',
        tdClass: 'text-center align-middle',
      },
      {
        label: 'Sender Address',
        field: 'sender_address',
        thClass: 'text-center align-middle',
        tdClass: 'text-center align-middle',
      },
      {
        label: 'Recipient Address',
        field: 'recipient_address',
        thClass: 'text-center align-middle',
        tdClass: 'text-center align-middle',
      },
    ]

    const status = [
      { label: 'Active', value: 'active' },
      { label: 'Scheduled', value: 'scheduled' },
      { label: 'History', value: 'history' },
    ]

    return {
      isLoading: false,
      columns,
      status,
      rows: [],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current      : 'light-primary',
        Professional : 'light-success',
        Rejected     : 'light-danger',
        Resigned     : 'light-warning',
        Applied      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.fetchOrders('active')
  },
  methods: {
    async fetchOrders(type) {
      this.resetRow()
      this.isLoading = true
      const query = `type=${type}`
      const response = await axios.get(`assigned-tracker?${query}`)
      this.rows = response.data
      this.currentStatus = type
      this.isLoading = false
    },
    resetRow() {
      this.rows = []
    },
  },
}
</script>
<style lang="scss" >
  @import '@core/scss/vue/libs/vue-good-table.scss';
</style>
